class DefaultInput {
    constructor(struct, value) {
        this.name = struct.name;
        this.visibility =  struct.visibility !== undefined ? struct.visibility : true;
        this.tooltip =  struct.tooltip ? struct.tooltip : '';
        this.info =  struct.info ? struct.info : '';
        this.value = value;
        this.error = false;
        this.errorCustom = false;
        this.customMessage = struct.customMessage ? struct.customMessage : 'error';
        this.customValidate = struct.customValidate ? struct.customValidate : null;
        this.validated = false;
    }
}

export class Calendar extends DefaultInput {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'calendar';
        this.value = value === null ? '' : value;

        this.label = struct.label ? struct.label : '';
        this.disabled = struct.disabled ? struct.disabled : false;
        this.required = struct.required ? struct.required : false;
        this.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';

        this.dateFormat = struct.dateFormat === undefined || struct.dateFormat === null  ? 'dd.mm.yy' : struct.dateFormat;
        this.minDate = struct.minDate === undefined || struct.minDate === null  ? null : struct.minDate;
        this.maxDate = struct.maxDate === undefined || struct.maxDate === null  ? null : struct.maxDate;


        this.errorRequired = false;
        this.errorMinLength = false;
        this.errorMaxLength = false;
    }
}

export class FileUpload extends DefaultInput {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'fileUpload';
        this.value = value === null ? '' : value;

        this.token = struct.token;
        this.label = struct.label ? struct.label : '';
        this.disabled = struct.disabled ? struct.disabled : false;
        this.required = struct.required ? struct.required : false;
        this.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';
        this.errorRequired = false;

        this.fileLimit = struct.fileLimit ? struct.fileLimit :  1;
        this.multiple = struct.multiple;
        this.url = struct.url;
    }
}



export class Editor extends DefaultInput {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'editor';
        this.value = value === null ? '' : value;

        this.token = struct.token;
        this.label = struct.label ? struct.label : '';
        this.disabled = struct.disabled ? struct.disabled : false;
        this.required = struct.required ? struct.required : false;
        this.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';
        this.errorRequired = false;
    }
}



export class InputText extends DefaultInput {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'text';
        this.value = value === null ? '' : value;

        this.label = struct.label ? struct.label : '';
        this.placeholder = struct.placeholder ? struct.placeholder : '';
        this.disabled = struct.disabled ? struct.disabled : false;
        this.required = struct.required ? struct.required : false;
        this.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';

        this.minLength = struct.minLength ? struct.minLength : 0;
        this.minLengthMessage = struct.minLengthMessage ? struct.minLengthMessage : 'Field must be at least ' +
            this.minLength+ ' characters long ';

        this.maxLength = struct.maxLength ? struct.maxLength : 255;
        this.maxLengthMessage = struct.maxLengthMessage ? struct.maxLengthMessage : 'The field can be max to ' +
            this.maxLength +' characters long';

        this.errorRequired = false;
        this.errorMinLength = false;
        this.errorMaxLength = false;
    }
}

export class TextArea extends InputText {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'textarea';
    }
}


export class InputEmail extends InputText {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'email';
        this.invalidEmailMessage = struct.invalidEmailMessage ? struct.invalidEmailMessage : 'Please enter a valid email address';
        this.errorInvalidEmail = false;
    }
}

export class InputPassword extends InputText {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'password';
        this.pattern = struct.pattern ? struct.pattern : null;
        this.patternMessage = struct.patternMessage ? struct.patternMessage : 'Password is too week';
        this.errorPattern = false;
    }
}

export class InputNumber extends InputText {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'number';
        this.value = value === null ? null : value;
        this.minFractionDigits = struct.minFractionDigits !== undefined ? struct.minFractionDigits : 0;
        this.maxFractionDigits = struct.maxFractionDigits !== undefined ? struct.maxFractionDigits : 2;
        this.locale = struct.locale !== undefined ? struct.locale : 'en-US';
        this.suffix = struct.suffix !== undefined ? struct.suffix : null;
        this.prefix = struct.prefix !== undefined ? struct.prefix : null;
        this.min = struct.min !== undefined ? struct.min : null;
        this.max = struct.max !== undefined ? struct.max : null;
        this.grouping = struct.grouping !== undefined ? struct.grouping : true;
    }
}

export class CheckBox extends DefaultInput {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'checkbox';
        this.value = value === null ? '' : value;

        this.label = struct.label ? struct.label : '';
        this.disabled = struct.disabled ? struct.disabled : false;
        this.required = struct.required ? struct.required : false;
        this.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';


        this.errorRequired = false;
        this.errorMinLength = false;
        this.errorMaxLength = false;
    }
}


export class Dropdown extends DefaultInput {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'dropdown';
        this.value = value === undefined || value === null  ? '' : value;

        this.label = struct.label ? struct.label : '';
        this.placeholder = struct.placeholder ? struct.placeholder : '';
        this.disabled = struct.disabled ? struct.disabled : false;
        this.required = struct.required ? struct.required : false;
        this.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';

        this.options = struct.options;
        this.optionLabel = struct.optionLabel;
        this.optionValue = struct.optionValue === undefined || struct.optionValue === null ? null : struct.optionValue;

        this.errorRequired = false;
        this.errorMinLength = false;
        this.errorMaxLength = false;
    }
}

export class RadioButtonInline extends DefaultInput {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'radioButtonInline';
        this.value = value === undefined || value === null  ? '' : value;

        this.label = struct.label ? struct.label : '';
        this.placeholder = struct.placeholder ? struct.placeholder : '';
        this.disabled = struct.disabled ? struct.disabled : false;
        this.required = struct.required ? struct.required : false;
        this.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';

        this.options = struct.options;
        this.optionLabel = struct.optionLabel;

        this.errorRequired = false;
        this.errorMinLength = false;
        this.errorMaxLength = false;
    }
}



export class AutoComplte extends DefaultInput {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'autocomplete';
        this.value = value === null ? '' : value;

        this.label = struct.label ? struct.label : '';
        this.placeholder = struct.placeholder ? struct.placeholder : '';
        this.disabled = struct.disabled ? struct.disabled : false;
        this.required = struct.required ? struct.required : false;
        this.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';

        this.delay = struct.delay ? struct.delay : 300;
        this.field = struct.field;
        this.minLength = struct.minLength ? struct.minLength : 1;
        this.autocomplete = struct.autocomplete;
        this.forceSelection = struct.forceSelection !== undefined ? struct.forceSelection : null;

        this.errorRequired = false;
        this.errorMinLength = false;
        this.errorMaxLength = false;
    }
}


export class Table extends DefaultInput {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'table';
        this.value = value === null ? [] : value;
        this.label = struct.label ? struct.label : '';
        this.disabled = struct.disabled ? struct.disabled : false;
        this.required = struct.required ? struct.required : false;
        this.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';
        this.columns = struct.columns ? struct.columns : [];
        this.addMethod = struct.addMethod ? struct.addMethod : () => null;
        this.filterMethod = struct.filterMethod !== undefined ? struct.filterMethod : null;
        this.editMethod = struct.editMethod ? struct.editMethod : () => null;
        this.removeMethod = struct.removeMethod ? struct.removeMethod : () => null;
    }
}

export class Action extends DefaultInput {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'action';
        this.label = struct.label ? struct.label : '';
        this.disabled = struct.disabled ? struct.disabled : false;
        this.buttonIcon = struct.buttonIcon ? struct.buttonIcon : 'pi pi-plus';
        this.buttonText = struct.buttonText ? struct.buttonText : '';
        this.buttonClass = struct.buttonClass ? struct.buttonClass : 'p-button-primary';
        this.buttonPosition = struct.buttonPosition ? struct.buttonPosition : 'left';
        this.callAction = struct.callAction ? struct.callAction : () => null;
        this.params = struct.params !== undefined ? struct.params : null
    }
}

export class Separator extends DefaultInput {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'separator';
    }
}

export class Label extends DefaultInput {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'label';
        this.label = struct.label ? struct.label : '';
    }
}

export class HtmlEditor extends DefaultInput {
    constructor(struct, value) {
        super(struct, value);
        this.type = 'htmlEditor';
        this.label = struct.label ? struct.label : '';
        this.disabled = struct.disabled ? struct.disabled : false;
        this.required = struct.required ? struct.required : false;
        this.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';
    }
}