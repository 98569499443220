<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <ml-form
                    :dataForm="formData"
                    :struct="formStruct"
                    @submittedForm="sendValue"
                    @canceledForm="cancelForm"
            />


        </div>
    </div>
</template>



<script>
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Form from '../../../form/ml/form/From.vue'


    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Ustawienia", routerName: 'dictionaryList', params: {}, query: {}},
                {label: "Użytkownicy", routerName: 'usersList', params: {}, query: {}},
                {label: "Edycja"}
            ]
            return {
                breadcrumpsList
            }
        },

        components: {
            Breadcrumbs,
            'ml-form': Form
        },


        data() {
            return {
                roles: [],
                formData: {
                    name: '',
                    description: '',
                    active: false
                },
                formStruct: {
                    fields: [],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                },


            }
        },


        methods: {

            async sendValue(data) {
                data.roles = [data.role];
                if (this.$route.params.id) {
                    await this.$store.dispatch('user/editUser', data);
                } else {
                    await this.$store.dispatch('user/createUser', data);
                }
                await this.$store.dispatch('user/findUsers');
                this.$router.replace({name: 'usersList'});

            },
            cancelForm() {
                this.$router.replace({name: 'usersList'});
            }

        },

        async beforeMount() {
            this.roles = await this.$store.dispatch('user/roles');

            if (this.$route.params.id) {
                let data = await this.$store.dispatch('user/findUser', this.$route.params.id);
                data.role = data.roles.length > 0 ? data.roles[0] : null;
                this.formData = data;
            } else {
                this.formData = {
                    email: '',
                    firstName: '',
                    lastName: '',
                    role: null
                }
            }

            this.formStruct = {
                rebuild: true,
                fields: [
                    {type: 'TEXT', name: 'email', label: 'Email', placeholder: 'Wpisz email',
                        tooltip: 'Email', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        maxLength: 200, maxLengthMessage: 'Pole może mieć maksymalnie 200 znaków'},

                    {type: 'TEXT', name: 'firstName', label: 'Imię', placeholder: 'Wpisz imię',
                        tooltip: 'Imię', info: '',
                        maxLength: 200, maxLengthMessage: 'Pole może mieć maksymalnie 200 znaków'},

                    {type: 'TEXT', name: 'lastName', label: 'Nazwisko', placeholder: 'Wpisz nazwisko',
                        tooltip: 'Nazwisko', info: '',
                        maxLength: 200, maxLengthMessage: 'Pole może mieć maksymalnie 200 znaków'},


                    {type: 'DROPDOWN', name: 'role', label: 'Rola',
                        placeholder: 'Rola',
                        tooltip: '', info: '', required: true,  requiredMessage: 'Pole jest wymagane',
                        options: this.roles, optionLabel: 'name'}

                ],
                cancelValue: 'Anuluj',
                submitValue: 'Zapisz'
            };

        }
    }

</script>