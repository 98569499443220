<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <ml-form
                    :dataForm="formData"
                    :struct="formStruct"
                    @submittedForm="sendValue"
                    @canceledForm="cancelForm"
            />

            <div class="message-info">
                <p>Zmienne w szablonie:</p>
                <ul>
                    <li v-for="(variable,index) in formData.messageVariables" :key="index">
                        <span class="var-name">{{variable.name}}</span>
                        <span class="var-desc">{{variable.description}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>



<script>
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Form from '../../../form/ml/form/From.vue'


    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "CMS", routerName: 'cmsGalleryFolderList', params: {}, query: {}},
                {label: "Szablony maili", routerName: 'mailList', params: {}, query: {}},
                {label: "Edycja"}
            ]
            return {
                breadcrumpsList
            }
        },

        components: {
            Breadcrumbs,
            'ml-form': Form
        },


        data() {
            return {
                formData: {
                    name: '',
                    description: '',
                    active: false
                },
                formStruct: {
                    fields: [
                        {type: 'TEXT', name: 'subject', label: 'Tytuł', placeholder: 'Wpisz tytuł',
                            tooltip: 'Wpisz tytuł maila', info: '',
                            required: true,  requiredMessage: 'Pole jest wymagane',
                            maxLength: 200, maxLengthMessage: 'Pole może mieć maksymalnie 200 znaków'},

                        {type: 'HTML_EDITOR', name: 'body', label: 'Treść maila'},

                        {type: 'TEXT', name: 'defaultOwner', label: 'Nadawca', placeholder: 'Wpisz nadawcę maila',
                            tooltip: 'Nadawca maila', info: '',
                            required: true,  requiredMessage: 'Pole jest wymagane',
                            maxLength: 200, maxLengthMessage: 'Pole może mieć maksymalnie 200 znaków'},

                        {type: 'TEXT', name: 'defaultOwnerEmail', label: 'Email nadawcy', placeholder: 'Wpisz nadawcę email',
                            tooltip: 'Email nadawcy maila', info: '',
                            required: true,  requiredMessage: 'Pole jest wymagane',
                            maxLength: 200, maxLengthMessage: 'Pole może mieć maksymalnie 200 znaków'},
                    ],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                }


            }
        },


        methods: {

            async sendValue(data) {
                await this.$store.dispatch('userMail/saveTemplate', data);
                await this.$store.dispatch('userMail/findTemplates');
                this.$router.replace({name: 'mailList'});

            },
            cancelForm() {
                this.$router.replace({name: 'mailList'});
            }

        },

        async beforeMount() {
            this.formData  = await this.$store.dispatch('userMail/findTemplate', this.$route.params.id);
        }
    }

</script>