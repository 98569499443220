<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">

            <pv-toolbar v-if="enableAction" class="mb-4">

                <template #start>
                    <pv-button label="Dodaj" icon="pi pi-plus" class="p-button-primary me-2" @click="create()"  />
                </template>

                <template #end>

                </template>
            </pv-toolbar>

            <pv-table
                    ref="dt"
                    :value="news"
                    :lazy="false"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[20,50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    :loading="loading"

                    filterDisplay="row"

            >

                <pv-column header="Zdjęcie" style="width: 100px" >
                    <template #body="{data}">
                        <img v-if="data.resource" :src="data.resource.link" style="width: 100%" />
                    </template>
                </pv-column>
                <pv-column field="title" header="Tytuł" style="width: auto" />
                <pv-column field="added" header="Data dodania" style="width: 200px" >
                    <template #body="{data}">
                        {{formatDate(data.added)}}
                    </template>
                </pv-column>




                <pv-column  style="width: 200px">
                    <template #body="{data}">
                        <pv-button icon="pi pi-pencil" class="p-button-rounded p-button-warning me-2" @click="edit(data)" />
                        <pv-button icon="pi pi-trash" class="p-button-rounded p-button-danger me-2" @click="deleteItem(data)" />
                    </template>
                </pv-column>
            </pv-table>

        </div>
    </div>
</template>

<script>

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Toolbar from 'primevue/toolbar'
    import Button from 'primevue/button'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'



    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "CMS", routerName: 'cmsGalleryFolderList', params: {}, query: {}},
                {label: "Aktualności"}
            ];
            return {
                breadcrumpsList
            }
        },


        components: {
            Breadcrumbs,
            'pv-toolbar': Toolbar,
            'pv-button': Button,
            'pv-table': DateTable,
            'pv-column': Column

        },



        data() {
            return {
                folders: [],
                loading: false,
                totalRecords: 0,
                enableAction: true,
                importing: false,
            }
        },


        methods: {

            create() {
                this.$router.replace({name: 'newsAdd',  params: {}});
            },

            edit(item) {
                this.$router.replace({name: 'newsEdit', params: {id: item.id}});
            },


            async deleteItem(item) {
                await this.$store.dispatch('news/removeNews', item.id);
                this.refreshTable();
            },


            async refreshTable() {
                this.news = await this.$store.dispatch('news/findNewsList');
                this.loading = false;
            },

            formatDate(value) {
                let date = new Date(value);
                return date.toLocaleDateString('pl-Pl', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });
            },


        },

        mounted() {
            this.loading = true;
            this.refreshTable();
        },

    }
</script>