<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <ml-form
                    :dataForm="formData"
                    :struct="formStruct"
                    @submittedForm="sendValue"
                    @canceledForm="cancelForm"
                    @changeValue="changeValue"
            />


        </div>
    </div>
</template>



<script>
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Form from '../../../form/ml/form/From.vue'

    export default {

        components: {
            Breadcrumbs,
            'ml-form': Form
        },


        data() {

            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "CMS", routerName: 'cmsGalleryFolderList', params: {}, query: {}},
                {label: "Galeria", routerName: 'cmsGalleryFolderList', params: {}, query: {}},
                {label: "Zdjęcia"}
            ];

            return {
                galleries: [],
                formData: {

                },
                formStruct: {fields: []},
                breadcrumpsList

            }
        },


        methods: {
            async changeValue(data) {
                console.log(data.value.length);
                data.value.forEach(async res => {
                    let findRes = this.galleries.find(g => g.resource.id == res.id);
                    if (!findRes) {
                        let data = {folder: {id: this.$route.params.id}, resource: res};
                        await this.$store.dispatch("gallery/saveImage", data);
                    }
                });
                //remove
                this.galleries.forEach(async g => {
                    let findRes = data.value.find(res => g.resource.id == res.id);
                    if (!findRes) {
                        await this.$store.dispatch("gallery/removeImage", g.id);
                    }

                });
                this.galleries = await this.$store.dispatch('gallery/findGallery', this.$route.params.id);

            },

            async sendValue(data) {
                data.resource = data.resource.length > 0 ? data.resource[0] : null;
                await this.$store.dispatch('gallery/saveFolder', data);
                this.$router.replace({name: 'cmsGalleryFolderList', params: {id: this.$route.params.userId}});

            },
            cancelForm() {
                this.$router.replace({name: 'cmsGalleryFolderList', params: {id: this.$route.params.userId}});
            }

        },

        async beforeMount() {
            let url = process.env.VUE_APP_API_HOST + '/api/cms/resources';
            let token = await this.$store.dispatch("api/getTicket");
            this.galleries = await this.$store.dispatch('gallery/findGallery', this.$route.params.id);
            this.formData = {
                resource: this.galleries.map(g => g.resource)
            };

            this.formStruct = {
                rebuild: true,
                fields: [
                    {type: 'FILE_UPLOAD', name: 'resource', label: '', token: token,
                        url: url, multiple: true,
                        info: '', required: false, fileLimit: 100},


                ],
                cancelValue: 'Zamknij',
                submitValue: ''
            };

        }
    }

</script>