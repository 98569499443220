import {UserRole, AccessType} from "@miro-labs/vue3-auth/AccessComponent";

function createSettings(application) {
    application.config.globalProperties.$setting = {
        architectLayout: {
            topMenu: [
                {name: "CMS", icon: "fa fa-brush", routerName: "cmsGalleryFolderList", menuId: 4000,
                    access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}},
                {name: "Administracja", icon: "fa fa-cog", routerName: "dictionaryList", menuId: 1000,
                    access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}},
            ],
            menu: {
                cms: [
                    {id: 4000, header: true, title: 'CMS', access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}},
                    {id: 4001, title: 'Galeria', active: true, routerName: 'cmsGalleryFolderList',
                        access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}},
                    {id: 4002, title: 'Blog', active: true, routerName: 'blogList',
                        access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}},
                    {id: 4003, title: 'Aktualności', active: true, routerName: 'newsList',
                        access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}},
                    {id: 4004, title: 'Strony', active: true, routerName: 'pageList',
                        access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}},
                    {id: 4005, title: 'Kadra', active: true, routerName: 'personList',
                        access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}},
                    {id: 4006, title: 'Oferta', active: true, routerName: 'offerList',
                        access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}},
                    {id: 4007, title: 'Szablony mail', active: true, routerName: 'mailList',
                        access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}},
                ],


                admin: [
                    {id: 1000, header: true, title: 'Administracja', access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}},
                    {id: 1100, header: false, title: 'Użytkownicy', active: true, open: true, icon: 'fa fa-users',
                        access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL},
                        children:[
                            {id: 1101, title: 'Lista', active: true, routerName: 'usersList',
                                access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}},
                        ]
                    },

                    {id: 1300, header: false, title: 'Maile', active: true, open: true, icon: 'fa fa-envelope',
                        access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL},
                        children:[

                            {id: 1301, title: 'Szablony', active: true, routerName: 'messageTemplateList',
                                access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}},
                        ]
                    },


                ]
            },
            redirectMainPage: 'signIn',
        },





        auth: {
            labels: {
                signInTitle: 'Logowanie',
                recoverPasswordTitle: 'Odzyskaj hasło',
                changePasswordTitle: 'Zmiana hasła',
                username: 'email',
                password: 'hasło',
                repeatPassword: 'powtórz hasło',
                email: 'email',
                submitTitle: 'Zaloguj się',
                recoverPasswordSubmit: 'Wyślij',
                changePasswordSubmit: 'Zmień',
                forgotPassword: 'Zapomniałeś hasła?',
                goToSignInPage: 'Zaloguj się',
                recoverInfo: 'Wpisz poniżej swój adres e-mail, a wyślemy Tobie instrukcje do zmiany hasła!'
            },
            logo: require('@/assets/images/logo-inverse.png'),
            logoRedirectName: 'signIn',
            redirectSignIn: 'cmsGalleryFolderList',
            invalidAuthTitle: 'Błąd autoryzacji',
            invalidAuthMessage: 'Nieprawidłowy login lub hasło',
            invalidEmailTitle: 'Nieprawidłowy e-mail',
            invalidEmailMessage: 'Podany adres email nie został znaleziony w naszym serwisie',
            recoverSuccess: 'Wiadomość wysłana',
            recoverSuccessMessage: 'Na podany adres email została wysłana do Ciebie instrukcja do zmiany hasła',

            validatePasswordTitle: 'Nieprawidłowe hasło',
            comparePasswordsMessage: 'Hasła muszą być takie same',
            patternPasswordMessage: 'Hasło jest zbyt słabe',
            patternPassword: 'medium',
            changePasswordError: 'Błąd zmiany hasła',
            changePasswordSuccess: 'Hasło zostało zmienione',
            changePasswordSuccessMessage: 'Możesz się teraz zalogować'
        }



    };
}

export {
    createSettings
}