import { createApp } from 'vue'
import App from './App.vue'
import BaseLayout from '@miro-labs/vue3-architect-layout/layout/BaseLayout.vue';
import router from './router/index'
import store from './state/store'
import PrimeVue from 'primevue/config';
import CommonLayout from '@miro-labs/vue3-auth/layout/CommonLayout.vue';
import {checkAccess} from '@miro-labs/vue3-auth/AccessComponent'
import {createSettings} from './setting'

import "bootstrap"
import "primevue/resources/themes/saga-blue/theme.css"
import "primevue/resources/primevue.min.css"
import 'primeicons/primeicons.css';




const app = createApp(App)
    .use(router)
    .use(store)
    .use(PrimeVue)
    .component('common-layout', CommonLayout)
    .component('base-layout', BaseLayout);

router.isReady().then(() => {
    app.mount('#app');
});


checkAccess(router, store);
createSettings(app);

