<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">

            <pv-toolbar v-if="enableAction" class="mb-4">

                <template #start>
                    <pv-button label="Dodaj" icon="pi pi-plus" class="p-button-primary me-2" @click="create()"  />
                </template>

                <template #end>

                </template>
            </pv-toolbar>

            <pv-table
                    ref="dt"
                    :value="keys"
                    :lazy="false"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[20,50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    :loading="loading"

                    filterDisplay="row"

            >

                <pv-column field="domainName" header="Domena" style="width: 400px" />
                <pv-column field="authKey" header="Klucz" style="width: auto" />




                <pv-column  style="width: 120px">
                    <template #body="{data}">
                        <pv-button icon="pi pi-pencil" class="p-button-rounded p-button-warning me-2" @click="edit(data)" />
                        <pv-button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteItem(data)" />
                    </template>
                </pv-column>
            </pv-table>

        </div>
    </div>
</template>

<script>

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Toolbar from 'primevue/toolbar'
    import Button from 'primevue/button'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'



    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Ustawienia", routerName: 'dictionaryList', params: {}, query: {}},
                {label: "Użytkownicy", routerName: 'usersList', params: {}, query: {}},
                {label: "Klucze"}
            ];
            return {
                breadcrumpsList
            }
        },


        components: {
            Breadcrumbs,
            'pv-toolbar': Toolbar,
            'pv-button': Button,
            'pv-table': DateTable,
            'pv-column': Column

        },



        data() {
            return {
                keys: [],
                loading: false,
                totalRecords: 0,
                enableAction: true,
                importing: false,
            }
        },


        methods: {

            create() {
                this.$router.replace({name: 'keyListAdd',  params: {userId: this.$route.params.id}});
            },

            edit(item) {
                this.$router.replace({name: 'keyListEdit', params: {userId: this.$route.params.id, id: item.id}});
            },

            async deleteItem(item) {
                await this.$store.dispatch('user/removeKey', item.id);
                this.refreshTable();
            },


            async refreshTable() {
                this.keys = await this.$store.dispatch('user/findKeys', this.$route.params.id);
                this.loading = false;
            },




        },

        mounted() {
            this.loading = true;
            this.refreshTable();
        },

    }
</script>