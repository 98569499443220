/* eslint-disable */
export default {
    namespaced: true,

    state: {
        users: [],
        roles: [],
    },

    mutations: {
        users(state, list) {
            list.forEach(l => l.role = l.roles.length > 0 ? l.roles[0] : null);
            state.users = list;
        }
    },

    actions: {

        async roles({state}) {
            state.roles = await this.dispatch('api/get', {path: '/api/user/roles'});
            return state.roles;
        },


        async findUsers({commit}) {
            let users = await this.dispatch('api/get', {path: '/api/user/list'});
            commit('users', users);
            return users;
        },

        async findUser({},  id) {
            return await this.dispatch('api/get', {path: '/api/user/' + id});
        },

        async createUser({}, user) {
            await this.dispatch('api/post', {path: '/api/user/add', data: user});
        },


        async editUser({}, user) {
            await this.dispatch('api/put', {path: '/api/user/edit', data: user});
        },



        async findKeys({}, userId) {
            return await this.dispatch('api/get', {path: '/api/user/keys/' + userId});
        },

        async findKey({}, id) {
            return await this.dispatch('api/get', {path: '/api/user/key/' + id});
        },

        async saveKey({}, params) {
            return await this.dispatch('api/post', {path: '/api/user/keys/' + params.userId, data: params.data});
        },

        async removeKey({}, id) {
            return await this.dispatch('api/delete', {path: '/api/user/key/' + id});
        },

    }
}