/* eslint-disable */
export default {
    namespaced: true,

    state: {
    },

    mutations: {

    },

    actions: {

        async findGallery({}, folderId) {
            return await this.dispatch('api/get', {path: '/api/cms/gallery/gallery/' + folderId});
        },

        async saveImage({}, data) {
            return await this.dispatch('api/post', {path: '/api/cms/gallery/gallery', data: data});
        },

        async removeImage({}, id) {
            return await this.dispatch('api/delete', {path: '/api/cms/gallery/gallery/' + id});
        },



        async findGalleryFolders({}, userId) {
            return await this.dispatch('api/get', {path: '/api/cms/gallery/folders'});
        },

        async findGalleryFolder({}, id) {
            return await this.dispatch('api/get', {path: '/api/cms/gallery/folder/' + id});
        },

        async saveFolder({}, data) {
            return await this.dispatch('api/post', {path: '/api/cms/gallery/folders', data: data});
        },

        async removeFolder({}, id) {
            return await this.dispatch('api/delete', {path: '/api/cms/gallery/folder/' + id});
        },

    }
}