<template>
    <div v-if="formInput.visibility" class="position-relative row mb-3">
        <label v-if="formInput.label" class="form-label col-sm-2 col-form-label" :for="formInput.name">{{formInput.label}}</label>
        <div :class="{'col-sm-10': formInput.label, 'col-sm-12': !formInput.label}">
            <div class="row form-row-gallery">
                <div v-for="resource in value" :key="resource.id" class="col-sm-2 form-image">
                    <pv-image :src="resource.link" :alt="resource.link"  />
                    <pv-button label="Usuń" class="p-button-text p-button-plain" @click="deleteItem(resource)"/>
                </div>

            </div>


            <pv-file-upload
                    v-if="visibleUpload()"
                    :id="formInput.name"
                    class="form-control"
                    :class="{'is-invalid': formInput.error, 'is-valid': formInput.validated}"
                    :disabled="formInput.disabled"
                    name="file"
                    :auto="true"
                    :chooseLabel="'Wybierz plik'"
                    :showUploadButton="false"
                    :showCancelButton="false"
                    :url="formInput.url"
                    :multiple="formInput.multiple"
                    :fileLimit="formInput.fileLimit"
                    :accept="'image/jpeg'"
                    @before-send="beforeUpload"
                    @upload="upload"
            />
            <div v-if="formInput.error" class="invalid-feedback">
                <span v-if="formInput.errorCustom">{{formInput.customMessage}}</span>
                <span v-if="formInput.errorRequired">{{formInput.requiredMessage}}</span>
            </div>
            <small class="form-text text-muted" v-if="formInput.info">
                {{formInput.info}}
            </small>
        </div>
    </div>
</template>



<script>

    import FileUpload from 'primevue/fileupload';
    import Image from 'primevue/image';
    import Button from 'primevue/button';

    export default {
        props: ['formInput'],
        emits: ['changeValue'],
        components: {
            'pv-file-upload': FileUpload,
            'pv-image': Image,
            'pv-button': Button
        },
        data() {
            return {
                value: []
            }
        },
        created() {
            this.value = this.formInput.value;
        },
        watch: {
            value(newValue) {
                if (this.formInput) {
                    this.$emit('changeValue', {name: this.formInput.name, value: newValue})
                }
            },
            formInput: {
                handler() {
                    this.value = this.formInput.value;
                },
                deep: true
            }
        },
        methods: {
            visibleUpload() {
                return this.value.length < this.formInput.fileLimit;
            },

            beforeUpload(request) {
                request.xhr.setRequestHeader(process.env.VUE_APP_API_HEADER_TOKEN, 'Bearer ' + this.formInput.token);
                return request;
            },
            upload(request) {
                if(request.xhr.response) {
                    let dataV = this.value;
                    let list = JSON.parse(request.xhr.response);
                    list.forEach(r =>  dataV.push(r));
                    this.value = dataV.filter(r => r.id > 0);
                }
            },
            deleteItem(resource) {
                let dataV = this.value.filter(r => r.id != resource.id);
                this.value = dataV;
            }
        }
    }
</script>