<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <ml-form
                    :dataForm="formData"
                    :struct="formStruct"
                    @submittedForm="sendValue"
                    @canceledForm="cancelForm"
                    @changeValue="changeValue"
            />


        </div>
    </div>
</template>



<script>
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Form from '../../../form/ml/form/From.vue'
    import {updateValue} from '../../../form/ml/form/class/InputsManager'

    export default {

        components: {
            Breadcrumbs,
            'ml-form': Form
        },


        data() {

            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "CMS", routerName: 'cmsGalleryFolderList', params: {}, query: {}},
                {label: "Galeria", routerName: 'cmsGalleryFolderList', params: {}, query: {}},
                {label: "Edycja"}
            ];

            return {
                roles: [],
                formData: {
                    name: '',
                    description: '',
                    active: false
                },
                formStruct: {fields: []},
                breadcrumpsList

            }
        },


        methods: {
            async changeValue(data) {
                updateValue(this.formData, data);
            },

            async sendValue(data) {
                data.resource = data.resource.length > 0 ? data.resource[0] : null;
                await this.$store.dispatch('gallery/saveFolder', data);
                this.$router.replace({name: 'cmsGalleryFolderList', params: {id: this.$route.params.userId}});

            },
            cancelForm() {
                this.$router.replace({name: 'cmsGalleryFolderList', params: {id: this.$route.params.userId}});
            }

        },

        async beforeMount() {
            let url = process.env.VUE_APP_API_HOST + '/api/cms/resources';
            let token = await this.$store.dispatch("api/getTicket");
            if (this.$route.params.id) {
                this.formData = await this.$store.dispatch('gallery/findGalleryFolder', this.$route.params.id);
                this.formData.resource = [this.formData.resource];
            } else {
                this.formData = {
                    title: '',
                    description: '',
                    resource: []
                }
            }

            this.formStruct = {
                rebuild: true,
                fields: [
                    {type: 'TEXT', name: 'title', label: 'Tytuł', placeholder: 'Wpisz tytuł',
                        tooltip: 'Wpisz tytuł folderu', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        maxLength: 200, maxLengthMessage: 'Pole może mieć maksymalnie 200 znaków'},
                    {type: 'TEXTAREA', name: 'description', label: 'Opis', placeholder: 'Wpisz opis',
                        tooltip: 'Wpisz opis', info: '', required: false,
                        maxLength: 800, maxLengthMessage: 'Pole może mieć maksymalnie 800 znaków'},
                    {type: 'CALENDAR', name: 'added', label: 'Data dodania', tooltip: 'Data publikacji', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane'},
                    {type: 'FILE_UPLOAD', name: 'resource', label: 'Zdjęcie', token: token,
                        url: url, multiple: false,
                        info: '', required: false, fileLimit: 1},


                ],
                cancelValue: 'Anuluj',
                submitValue: 'Zapisz'
            };

        }
    }

</script>