/* eslint-disable */
export default {
    namespaced: true,

    state: {
        templates: [],
    },

    mutations: {

    },

    actions: {
        async findTemplates({state}) {
            state.templates = await this.dispatch('api/get', {path: '/api/messages/user-templates'});
            return state.templates;
        },

        async saveTemplate({}, template) {
            await this.dispatch('api/put', {path: '/api/messages/user-template/edit', data: template});
        },

        async findTemplate({state},  id) {
            return await this.dispatch('api/get', {path: '/api/messages/template/' + id});
        },
    }
}