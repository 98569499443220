<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <ml-form
                    :dataForm="formData"
                    :struct="formStruct"
                    @submittedForm="sendValue"
                    @canceledForm="cancelForm"
                    @changeValue="changeValue"
            />


        </div>
    </div>
</template>



<script>
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Form from '../../../form/ml/form/From.vue'
    import {updateValue} from '../../../form/ml/form/class/InputsManager'

    export default {

        components: {
            Breadcrumbs,
            'ml-form': Form
        },


        data() {

            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "CMS", routerName: 'cmsGalleryFolderList', params: {}, query: {}},
                {label: "Strony", routerName: 'pageList', params: {}, query: {}},
                {label: "Edycja"}
            ];

            return {
                formData: {
                    title: '',
                    content: '',
                },
                formStruct: {fields: []},
                breadcrumpsList

            }
        },


        methods: {
            async changeValue(data) {
                updateValue(this.formData, data);
            },

            async sendValue(data) {
                await this.$store.dispatch('page/savePage', data);
                this.$router.replace({name: 'pageList', params: {id: this.$route.params.userId}});

            },
            cancelForm() {
                this.$router.replace({name: 'pageList', params: {id: this.$route.params.userId}});
            }

        },

        async beforeMount() {

            if (this.$route.params.id) {
                this.formData = await this.$store.dispatch('page/findPage', this.$route.params.id);
            } else {
                this.formData = {
                    title: '',
                    content: ''
                }
            }

            this.formStruct = {
                rebuild: true,
                fields: [
                    {type: 'TEXT', name: 'title', label: 'Tytuł', placeholder: 'Wpisz tytuł',
                        tooltip: 'Wpisz tytuł', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        maxLength: 200, maxLengthMessage: 'Pole może mieć maksymalnie 200 znaków'},
                    {type: 'EDITOR', name: 'content', label: 'Treść',
                        info: '', required: true},

                ],
                cancelValue: 'Anuluj',
                submitValue: 'Zapisz'
            };

        }
    }

</script>