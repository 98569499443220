/* eslint-disable */
export default {
    namespaced: true,

    state: {
    },

    mutations: {

    },

    actions: {


        async findPages({}, userId) {
            return await this.dispatch('api/get', {path: '/api/cms/pages'});
        },

        async findPage({}, id) {
            return await this.dispatch('api/get', {path: '/api/cms/pages/' + id});
        },

        async savePage({}, data) {
            return await this.dispatch('api/post', {path: '/api/cms/pages', data: data});
        },

        async removePage({}, id) {
            return await this.dispatch('api/delete', {path: '/api/cms/pages/' + id});
        },

    }
}