import { createWebHistory, createRouter } from "vue-router";
import {UserRole, AccessType} from "@miro-labs/vue3-auth/AccessComponent";
import AuthRouter from "@miro-labs/vue3-auth/router"
import SignIn from "@miro-labs/vue3-auth/components/SignIn.vue"
import CMSRouter from "../components/cms/router"



import DictionaryList from "../components/admin/dictionary/template/DictionaryList.vue";
import DictionaryForm from "../components/admin/dictionary/template/DictionaryForm.vue";
import DictionaryValueList from "../components/admin/dictionary/template/DictionaryValueList.vue";
import DictionaryValueForm from "../components/admin/dictionary/template/DictionaryValueForm.vue";
import DictionaryFieldList from "../components/admin/dictionary/template/DictionaryFieldList.vue";
import DictionaryFieldForm from "../components/admin/dictionary/template/DictionaryFieldForm.vue";

import MessageTemplateList from "../components/admin/message/template/MessageTemplateList.vue";
import MessageTemplateForm from "../components/admin/message/template/MessageTemplateForm.vue";

import UserList from "../components/admin/user/template/UserList.vue";
import UserForm from "../components/admin/user/template/UserForm.vue";
import KeyList from "../components/admin/user/template/AuthKeyList.vue";
import KeyForm from "../components/admin/user/template/AuthKeyForm.vue";



const routes = [
    ...AuthRouter,
    ...CMSRouter,
    {
        path: "/",
        name: "mainPage",
        component: SignIn,
        meta: {
            layout: 'common',
            menu: 'common',
            access: {roles: [UserRole.ANONYMOUS], type: AccessType.LEVEL}
        },
    },
    {
        path: "/adm/dictionary/list",
        name: "dictionaryList",
        component: DictionaryList,
        meta: {
            layout: 'base',
            menu: 'admin',
            access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}
        },
    },
    {
        path: "/adm/dictionary/add",
        name: "dictionaryAdd",
        component: DictionaryForm,
        meta: {
            layout: 'base',
            menu: 'admin',
            access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}
        },
    },
    {
        path: "/adm/dictionary/edit/:id",
        name: "dictionaryEdit",
        component: DictionaryForm,
        meta: {
            layout: 'base',
            menu: 'admin',
            access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}
        },
    },
    {
        path: "/adm/dictionary/values/:id",
        name: "dictionaryValueList",
        component: DictionaryValueList,
        meta: {
            layout: 'base',
            menu: 'admin',
            access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}
        },
    },
    {
        path: "/adm/dictionary/values/:dictionaryId/add",
        name: "dictionaryValueAdd",
        component: DictionaryValueForm,
        meta: {
            layout: 'base',
            menu: 'admin',
            access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}
        },
    },
    {
        path: "/adm/dictionary/values/:dictionaryId/edit/:id",
        name: "dictionaryValueEdit",
        component: DictionaryValueForm,
        meta: {
            layout: 'base',
            menu: 'admin',
            access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}
        },
    },
    {
        path: "/adm/dictionary/fields",
        name: "dictionaryFieldList",
        component: DictionaryFieldList,
        meta: {
            layout: 'base',
            menu: 'admin',
            access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}
        },
    },
    {
        path: "/adm/dictionary/fields/:type",
        name: "dictionaryFieldEdit",
        component: DictionaryFieldForm,
        meta: {
            layout: 'base',
            menu: 'admin',
            access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}
        },
    },
    {
        path: "/adm/messages/list",
        name: "messageTemplateList",
        component: MessageTemplateList,
        meta: {
            layout: 'base',
            menu: 'admin',
            access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}
        },
    },
    {
        path: "/adm/messages/edit/:id",
        name: "messageTemplateForm",
        component: MessageTemplateForm,
        meta: {
            layout: 'base',
            menu: 'admin',
            access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}
        },
    },

    {
        path: "/adm/user/list",
        name: "usersList",
        component: UserList,
        meta: {
            layout: 'base',
            menu: 'admin',
            access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}
        },
    },
    {
        path: "/adm/user/add",
        name: "createUser",
        component: UserForm,
        meta: {
            layout: 'base',
            menu: 'admin',
            access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}
        },
    },
    {
        path: "/adm/user/edit/:id",
        name: "editUser",
        component: UserForm,
        meta: {
            layout: 'base',
            menu: 'admin',
            access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}
        },
    },
    {
        path: "/adm/user/keys/:id",
        name: "keyList",
        component: KeyList,
        meta: {
            layout: 'base',
            menu: 'admin',
            access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}
        },
    },
    {
        path: "/adm/user/keys/:userId/add",
        name: "keyListAdd",
        component: KeyForm,
        meta: {
            layout: 'base',
            menu: 'admin',
            access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}
        },
    },
    {
        path: "/adm/user/keys/:userId/edit/:id",
        name: "keyListEdit",
        component: KeyForm,
        meta: {
            layout: 'base',
            menu: 'admin',
            access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}
        },
    },










];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
});

export default router;