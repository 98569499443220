<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <ml-form
                    :dataForm="formData"
                    :struct="formStruct"
                    @submittedForm="sendValue"
                    @canceledForm="cancelForm"
            />


        </div>
    </div>
</template>



<script>
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Form from '../../../form/ml/form/From.vue'


    export default {

        components: {
            Breadcrumbs,
            'ml-form': Form
        },


        data() {

            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Ustawienia", routerName: 'dictionaryList', params: {}, query: {}},
                {label: "Użytkownicy", routerName: 'usersList', params: {}, query: {}},
                {label: "Klucze", routerName: 'keyList', params: {id: this.$route.params.userId}, query: {}},
                {label: "Edycja"}
            ];

            return {
                roles: [],
                formData: {
                    name: '',
                    description: '',
                    active: false
                },
                formStruct: {fields: []},
                breadcrumpsList

            }
        },


        methods: {

            async sendValue(data) {
                await this.$store.dispatch('user/saveKey', {userId: this.$route.params.userId, data: data});
                this.$router.replace({name: 'keyList', params: {id: this.$route.params.userId}});

            },
            cancelForm() {
                this.$router.replace({name: 'keyList', params: {id: this.$route.params.userId}});
            }

        },

        async beforeMount() {
            if (this.$route.params.id) {
                this.formData = await this.$store.dispatch('user/findKey', this.$route.params.id);
            } else {
                this.formData = {
                    domainName: '',
                }
            }

            this.formStruct = {
                rebuild: true,
                fields: [
                    {type: 'TEXT', name: 'domainName', label: 'Domena', placeholder: 'Wpisz domene',
                        tooltip: 'Wpisz domenę z której będzie można uzyskać autoryzację', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        maxLength: 200, maxLengthMessage: 'Pole może mieć maksymalnie 200 znaków'},


                ],
                cancelValue: 'Anuluj',
                submitValue: 'Zapisz'
            };

        }
    }

</script>