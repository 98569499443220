/* eslint-disable */
export default {
    namespaced: true,

    state: {
    },

    mutations: {

    },

    actions: {


        async findPersons({}, userId) {
            return await this.dispatch('api/get', {path: '/api/cms/persons'});
        },

        async findPerson({}, id) {
            return await this.dispatch('api/get', {path: '/api/cms/persons/' + id});
        },

        async savePerson({}, data) {
            return await this.dispatch('api/post', {path: '/api/cms/persons', data: data});
        },

        async removePerson({}, id) {
            return await this.dispatch('api/delete', {path: '/api/cms/persons/' + id});
        },

    }
}