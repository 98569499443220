/* eslint-disable */
export default {
    namespaced: true,

    state: {
    },

    mutations: {

    },

    actions: {


        async findOffers({}) {
            console.log('find offers');
            return await this.dispatch('api/get', {path: '/api/cms/offers'});
        },

        async findOffer({}, id) {
            return await this.dispatch('api/get', {path: '/api/cms/offers/' + id});
        },

        async saveOffer({}, data) {
            return await this.dispatch('api/post', {path: '/api/cms/offers', data: data});
        },

        async removeOffer({}, id) {
            return await this.dispatch('api/delete', {path: '/api/cms/offers/' + id});
        },

    }
}