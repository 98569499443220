/* eslint-disable */
export default {
    namespaced: true,

    state: {
    },

    mutations: {

    },

    actions: {


        async findBlogs({}, userId) {
            return await this.dispatch('api/get', {path: '/api/cms/blogs'});
        },

        async findBlog({}, id) {
            return await this.dispatch('api/get', {path: '/api/cms/blogs/' + id});
        },

        async saveBlog({}, data) {
            return await this.dispatch('api/post', {path: '/api/cms/blogs', data: data});
        },

        async removeBlog({}, id) {
            return await this.dispatch('api/delete', {path: '/api/cms/blogs/' + id});
        },

    }
}