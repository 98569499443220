/* eslint-disable */

const fieldTypes = [
    { type: "TECHNICAL_CABINET", name: "Szafki oświetleniowe"},
    { type: "CABLE_LINE", name: "Linie kablowe"},
    { type: "PYLON", name: "Słupy"},
    { type: "JIB", name: "Ramiona słupów"},
    { type: "CHANNEL", name: "Przepusty"},
    { type: "LAMP_HOLDER", name: "Oprawy"},
    { type: "AREA", name: "Działki"},
];

export default {
    namespaced: true,

    state: {
        dictionares: [],
        fields: []
    },

    mutations: {
        fields(state, data) {
            state.fields = [];
            data.forEach(type => {
                let obj = fieldTypes.find(f => f.type == type);
                if (obj) {
                    state.fields.push(obj);
                } else {
                    state.fields.push({type: type, name: type});
                }

            })
        }

    },

    actions: {

        async findDictionares({state}) {
            state.dictionares = await this.dispatch('api/get', {path: '/api/map/dictionaries/list'});
            return state.dictionares;
        },

        async saveDictionary({}, dictionary) {
            await this.dispatch('api/post', {path: '/api/map/dictionaries/edit', data: dictionary});
        },

        async findDictionaryValues({}, id) {
            return await this.dispatch('api/get', {path: '/api/map/dictionaries/values/' + id});
        },

        async statusDictionaryValue({}, data) {
            await this.dispatch('api/put', {path: '/api/map/dictionaries/value/status', data: data});
        },

        async saveDictionaryValue({}, dictionaryValue) {
            await this.dispatch('api/post', {path: '/api/map/dictionaries/value/edit', data: dictionaryValue});
        },

        async deleteDictionaryValue({}, id) {
            await this.dispatch('api/delete', {path: '/api/map/dictionaries/value/' + id});
        },

        async findFields({commit}) {
            let data = await this.dispatch('api/get', {path: '/api/map/dictionaries/fields'});
            commit('fields', data);
        },

        async findFieldsByType({}, type) {
            return await this.dispatch('api/get', {path: '/api/map/dictionaries/fields/assign/' + type});
        },

        async saveFields({}, data) {
            return await this.dispatch('api/post', {path: '/api/map/dictionaries/fields/edit', data: data});
        },

        async findAttributes({}, type) {
            let attrubutes =  await this.dispatch('api/get', {path: '/api/map/dictionaries/fields/assign/' + type});
            let dictionartListActive = attrubutes.filter(a => a.active).map(a => a.dictionary);
            let result = [];
            for(let dict of dictionartListActive) {
                let values = await this.dispatch('api/get', {path: '/api/map/dictionaries/values/' + dict.id});
                result.push({
                    dictionary: dict,
                    values: values.filter(v => v.active)
                });
            }
            return result;
        },
    }
}