/* eslint-disable */
export default {
    namespaced: true,

    state: {
    },

    mutations: {

    },

    actions: {


        async findNewsList({}, userId) {
            return await this.dispatch('api/get', {path: '/api/cms/news'});
        },

        async findNews({}, id) {
            return await this.dispatch('api/get', {path: '/api/cms/news/' + id});
        },

        async saveNews({}, data) {
            return await this.dispatch('api/post', {path: '/api/cms/news', data: data});
        },

        async removeNews({}, id) {
            return await this.dispatch('api/delete', {path: '/api/cms/news/' + id});
        },

    }
}