import {UserRole, AccessType} from "@miro-labs/vue3-auth/AccessComponent";

import GalleryFolderList from "./gallery/template/GalleryFolderList.vue"
import GalleryFolderForm from "./gallery/template/GalleryFolderForm.vue"
import GalleryForm from "./gallery/template/Gallery.vue"

import BlogList from "./blog/template/BlogList.vue"
import BlogForm from "./blog/template/BlogForm.vue"

import NewsList from "./news/template/NewsList.vue"
import NewsForm from "./news/template/NewsForm.vue"

import PageList from "./page/template/PageList.vue"
import PageForm from "./page/template/PageForm.vue"

import PersonList from "./person/template/PersonList.vue"
import PersonForm from "./person/template/PersonForm.vue"

import MessageTemplateList from "./mails/template/MessageTemplateList.vue"
import MessageTemplateForm from "./mails/template/MessageTemplateForm.vue"


import OfferList from "./offer/template/OfferList.vue"
import OfferForm from "./offer/template/OfferForm.vue"

export default [
    {
        path: "/cms/gallery/folders",
        name: "cmsGalleryFolderList",
        component: GalleryFolderList,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },

    {
        path: "/cms/gallery/folder/add",
        name: "cmsGalleryFolderAdd",
        component: GalleryFolderForm,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },

    {
        path: "/cms/gallery/folder/edit/:id",
        name: "cmsGalleryFolderEdit",
        component: GalleryFolderForm,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },

    {
        path: "/cms/gallery/folder/gallery/:id",
        name: "cmsGalleryFolderGallery",
        component: GalleryForm,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },



    {
        path: "/cms/blogs",
        name: "blogList",
        component: BlogList,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },

    {
        path: "/cms/blogs/add",
        name: "blogAdd",
        component: BlogForm,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },

    {
        path: "/cms/blogs/edit/:id",
        name: "blogEdit",
        component: BlogForm,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },



    {
        path: "/cms/pages",
        name: "pageList",
        component: PageList,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },

    {
        path: "/cms/pages/add",
        name: "pageAdd",
        component: PageForm,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },

    {
        path: "/cms/pages/edit/:id",
        name: "pageEdit",
        component: PageForm,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },


    {
        path: "/cms/mails",
        name: "mailList",
        component: MessageTemplateList,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },



    {
        path: "/cms/mails/edit/:id",
        name: "mailEdit",
        component: MessageTemplateForm,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },



    {
        path: "/cms/news",
        name: "newsList",
        component: NewsList,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },

    {
        path: "/cms/news/add",
        name: "newsAdd",
        component: NewsForm,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },

    {
        path: "/cms/news/edit/:id",
        name: "newsEdit",
        component: NewsForm,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },




    {
        path: "/cms/persons",
        name: "personList",
        component: PersonList,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },

    {
        path: "/cms/persons/add",
        name: "personAdd",
        component: PersonForm,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },

    {
        path: "/cms/persons/edit/:id",
        name: "personEdit",
        component: PersonForm,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },


    {
        path: "/cms/offers",
        name: "offerList",
        component: OfferList,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },

    {
        path: "/cms/offers/add",
        name: "offerAdd",
        component: OfferForm,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },

    {
        path: "/cms/offers/edit/:id",
        name: "offerEdit",
        component: OfferForm,
        meta: {
            layout: 'base',
            menu: 'cms',
            access: {roles: [UserRole.WORKER], type: AccessType.LEVEL}
        },
    },

];