<template>
    <div v-if="formInput.visibility" class="position-relative row mb-3">
        <label class="form-label col-sm-2 col-form-label" :for="formInput.name">{{formInput.label}}</label>
        <div class="col-sm-10">
            <pv-editor
                    :id="formInput.name"
                    class="form-control"
                    :class="{'is-invalid': formInput.error, 'is-valid': formInput.validated}"
                    :disabled="formInput.disabled"
                    editorStyle="height: 400px"
                    v-model="value">
                <template v-slot:toolbar>
                    <span class="ql-formats">
                        <select class="ql-size">
                            <option value="small"></option>
                            <option selected></option>
                            <option value="large"></option>
                            <option value="huge"></option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                        <button class="ql-strike"></button>
                    </span>
                    <span class="ql-formats">
                        <select class="ql-color"></select>
                        <select class="ql-background"></select>
                    </span>
                    <span class="ql-formats">
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                        <select class="ql-align">
                                <option selected></option>
                                <option value="center"></option>
                                <option value="right"></option>
                                <option value="justify"></option>
                        </select>
                    </span>
                    <span class="ql-formats">
                        <button class="ql-link" value="ordered"></button>
                    </span>
                </template>
            </pv-editor>
            <div v-if="formInput.error" class="invalid-feedback">
                <span v-if="formInput.errorCustom">{{formInput.customMessage}}</span>
                <span v-if="formInput.errorRequired">{{formInput.requiredMessage}}</span>
            </div>
            <small class="form-text text-muted" v-if="formInput.info">
                {{formInput.info}}
            </small>
        </div>
    </div>
</template>

<script>

    import Editor from 'primevue/editor';


    export default {
        props: ['formInput'],
        emits: ['changeValue'],
        components: {
            'pv-editor': Editor,
        },
        data() {
            return {
                value: ''
            }
        },
        created() {
            this.value = this.formInput.value;
        },
        watch: {
            value(newValue) {
                if (this.formInput) {
                    this.$emit('changeValue', {name: this.formInput.name, value: newValue})
                }
            },
            formInput: {
                handler() {
                    this.value = this.formInput.value;
                },
                deep: true
            }
        }
    }
</script>