import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

import MessageStore from '@miro-labs/vue3-message/MessageStore'
import ApiStore from '@miro-labs/vue3-api/ApiStore'
import MenuStore from '@miro-labs/vue3-architect-layout/layout/base/menu/MenuStore'
import AuthStore from '@miro-labs/vue3-auth/AuthStore'

import DictionaryStore from '../components/admin/dictionary/DictionaryStore'
import MailStore from '../components/admin/message/MessageStore'
import UserStore from '../components/admin/user/UserStore'
import GalleryStore from '../components/cms/gallery/GalleryStore'
import BlogStore from '../components/cms/blog/BlogStore'
import NewsStore from '../components/cms/news/NewsStore'
import PageStore from '../components/cms/page/PageStore'
import UserMail from '../components/cms/mails/MailStore'
import PersonStore from '../components/cms/person/PersonStore'
import OfferStore from '../components/cms/offer/OfferStore'


const debug = process.env.NODE_ENV !== 'production';

const store = createStore({
    plugins: [createPersistedState({
        key: 'easy-way-vuex',
        paths: ['menu', 'adminMap']
    })],
    struct: debug,
    modules: {
        api: ApiStore,
        message: MessageStore,
        menu: MenuStore,
        auth: AuthStore,
        dictionary: DictionaryStore,
        mail: MailStore,
        user: UserStore,
        gallery: GalleryStore,
        blog: BlogStore,
        news: NewsStore,
        page: PageStore,
        userMail: UserMail,
        person: PersonStore,
        offer: OfferStore
    },
    state: {},
    getters: {},
    mutations: {},
    actions: {}
});

export default store;