<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">

            <pv-table
                    ref="dt"
                    :value="templates"
                    :lazy="false"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[20,50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    :loading="loading"

                    v-model:filters="filters"
                    filterDisplay="row"

            >

                <pv-column field="type" header="Rodzaj" style="width: 300px">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>

                <pv-column field="subject" header="Tytuł" style="width: auto">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>


                <pv-column  style="width: 120px">
                    <template #body="{data}">
                        <pv-button icon="pi pi-pencil" class="p-button-rounded p-button-warning me-2" @click="edit(data)" />
                    </template>
                </pv-column>
            </pv-table>

        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Button from 'primevue/button'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import InputText from 'primevue/inputtext'
    import {FilterMatchMode} from 'primevue/api';



    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "CMS", routerName: 'cmsGalleryFolderList', params: {}, query: {}},
                {label: "Szablony maili"}
            ];
            return {
                breadcrumpsList
            }
        },


        components: {
            Breadcrumbs,
            'pv-button': Button,
            'pv-table': DateTable,
            'pv-column': Column,
            'pv-input-text': InputText,

        },

        computed: {
            ...mapState({
                templates: state => state.userMail.templates
            }),
        },


        data() {
            return {
                loading: false,
                totalRecords: 0,
                enableAction: true,
                importing: false,
                filters: {
                    'type': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'subject': {value: '', matchMode: FilterMatchMode.CONTAINS},
                },
            }
        },


        methods: {



            edit(item) {
                this.$router.replace({name: 'mailEdit', params: {id: item.id}});
            },




            async refreshTable() {
                this.$store.dispatch('userMail/findTemplates');
                this.loading = false;
            },




        },

        mounted() {
            this.loading = true;
            this.refreshTable();
        },

    }
</script>